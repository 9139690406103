@import url(https://fonts.googleapis.com/css?family=Barriecito|Patrick+Hand&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
h1, h2, h3, h4, h5 {
  margin-top: .3em;
  margin-bottom: .3em;
  color: #000;
  font-family: "Barriecito", cursive;
}

p, li, a, label, button {
  margin-top: .5em;
  margin-bottom: .5em;
  color: #333232;
  font-family: "Patrick Hand", cursive;
}

input {
  font-family: "Patrick Hand", cursive;
}

h1 {
  font-size: 3em;
}

h2 {
  font-size: 2.5em;
}

h3 {
  font-size: 2em;
  font-weight: 600;
}

h4 {
  font-size: 1.5em;
  font-weight: 800;
}

h5 {
  font-size: 1.25em;
  font-weight: 900;
}

p, li, a {
  font-size: 1em;
}

a {
  font-weight: 900;
  color: #992893;
  text-decoration: none;
}

a:hover, a:active {
  color: #52244f;
  font-weight: 800;
}

button {
  background: #9de48c;
  border: none;
  border-radius: 10%;
  cursor: pointer;
}

button:disabled {
  background-color: gray;
  cursor: not-allowed;
}

#routes_top_container {
  width: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.65)), to(rgba(255, 255, 255, 0.65))), url(/static/media/bg_guest_landing.2b01827c.jpg) no-repeat center center fixed;
  background: linear-gradient(rgba(255, 255, 255, 0.65), rgba(255, 255, 255, 0.65)), url(/static/media/bg_guest_landing.2b01827c.jpg) no-repeat center center fixed;
  background-size: cover;
  background-color: #000;
}

#routes_top_container #header_container {
  width: 100%;
  display: flex;
  justify-content: center;
}

#routes_top_container #header_container header {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#routes_top_container #header_container header h2, #routes_top_container #header_container header h3 {
  text-align: center;
}

#routes_top_container #header_container header #site-tag {
  width: 100%;
  display: flex;
  margin-top: .5em;
  margin-bottom: .75em;
  justify-content: space-around;
  align-items: center;
}

#routes_top_container #header_container header #site-tag img {
  width: 100px;
}

#routes_top_container #header_container header #site-tag span {
  display: flex;
}

#routes_top_container #header_container header #loginlogout_loggedout {
  width: 100%;
  display: flex;
  flex-direction: column;
}

#routes_top_container #header_container header #loginlogout_loggedout span {
  margin-bottom: .5em;
  display: flex;
  justify-content: space-between;
}

#routes_top_container #header_container header #loginlogout_loggedin {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

#routes_top_container main {
  width: 100%;
  padding-bottom: 1em;
  display: flex;
  flex-direction: column;
}

#routes_top_container main .new_notification {
  display: inline-block;
  padding: .5em;
  color: #f5ff70;
  font-size: .5em;
  font-weight: 800;
  background: #3b3a3a;
  border-radius: 50%;
  position: relative;
  left: -25px;
  -webkit-transform: rotate(20deg) translateY(-10px);
          transform: rotate(20deg) translateY(-10px);
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.75);
}

#routes_top_container main img {
  width: 150px;
  margin-top: 1em;
  margin-bottom: 1em;
}

#routes_top_container main .dashboard {
  width: 100%;
  margin-top: .40em;
  margin-bottom: .40em;
  display: flex;
  justify-content: space-around;
}

#routes_top_container main #guest_landing {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#routes_top_container main #guest_landing p {
  padding: 5px;
  color: #fff;
  line-height: 1.5em;
  background: rgba(0, 0, 0, 0.5);
}

#routes_top_container main #guest_landing .guest_site_desc {
  width: 80%;
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: center;
}

#routes_top_container main #guest_landing .guest_info {
  display: flex;
  flex-direction: column;
}

#routes_top_container main #guest_landing .guest_info p {
  align-self: flex-end;
}

#routes_top_container main #guest_landing .second_register_invite {
  margin-top: 2em;
  font-weight: 600;
}

#routes_top_container main #browse {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

#routes_top_container main #browse .categories {
  margin-bottom: .5em;
  padding: .25em;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid;
  border-color: #8b8686;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.25);
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 20px;
}

#routes_top_container main #browse .categories .category_item {
  text-align: center;
}

#routes_top_container main #browse .categories .category_item img {
  width: 90%;
  margin-top: 0;
  margin-bottom: 0;
}

#routes_top_container main .product_summary {
  margin-bottom: .5em;
  padding: .25em;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid;
  border-color: #8b8686;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.25);
  width: 100%;
  display: flex;
}

#routes_top_container main .product_summary .img_container {
  width: 150px;
  margin-right: 1em;
  background: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

#routes_top_container main .product_summary .item_info {
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
}

#routes_top_container main .product_summary .item_info button {
  align-self: flex-end;
  justify-self: flex-end;
}

#routes_top_container main .barter_section {
  margin-bottom: .5em;
  padding: .25em;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid;
  border-color: #8b8686;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.25);
  width: 100%;
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: column;
}

#routes_top_container main .barter_section h3 {
  margin-bottom: .5em;
  -ms-grid-row-align: center;
  align-self: center;
}

#routes_top_container main .barter_section span {
  margin-bottom: 2em;
  display: flex;
  justify-content: space-between;
}

#routes_top_container main .barter_section label {
  width: 40%;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
}

#routes_top_container main .barter_section input, #routes_top_container main .barter_section textarea {
  width: 60%;
}

#routes_top_container main .barter_section textarea {
  min-height: 125px;
}

#routes_top_container main .barter_section > button {
  -ms-grid-row-align: center;
  align-self: center;
}

#routes_top_container main .barter_section .select_barter_item {
  width: 215px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#routes_top_container main .barter_section .select_barter_item p {
  width: 225px;
}

#routes_top_container main .offers_dashboard ul {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

#routes_top_container main .offers_detail_section {
  width: 100%;
  padding-top: .5em;
  padding-left: 1em;
  padding-right: 1em;
  background: #fff;
}

#routes_top_container main .offers_detail {
  margin-bottom: .5em;
  padding: .25em;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid;
  border-color: #8b8686;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.25);
}

#routes_top_container main .closed_offers .offer_closed {
  margin-bottom: .5em;
  padding: .25em;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid;
  border-color: #8b8686;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.25);
  background: #e0b3b3;
}

#routes_top_container main #inventory_dashboard {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

#routes_top_container main .inventory_item {
  margin-bottom: .5em;
  padding: .25em;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid;
  border-color: #8b8686;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
}

#routes_top_container main .input_form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

#routes_top_container main .input_form h3 {
  margin-bottom: .5em;
  -ms-grid-row-align: center;
  align-self: center;
}

#routes_top_container main .input_form span {
  margin-bottom: 2em;
  display: flex;
  justify-content: space-between;
}

#routes_top_container main .input_form label {
  width: 40%;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
}

#routes_top_container main .input_form input, #routes_top_container main .input_form textarea {
  width: 60%;
}

#routes_top_container main .input_form textarea {
  min-height: 125px;
}

#routes_top_container main #register_update_user {
  margin-bottom: .5em;
  padding: .25em;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid;
  border-color: #8b8686;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.25);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#routes_top_container main .footer_linked_component {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#site_footer {
  width: 100%;
  padding: 1em 2.5em 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#site_footer p {
  color: #000;
  font-weight: 800;
}

#site_footer div {
  width: 90%;
}

#site_footer div ul {
  display: flex;
  flex-direction: column;
}

#site_footer ul {
  width: 100%;
  margin-top: 1.5em;
  display: flex;
  justify-content: space-between;
}

@media only screen and (min-width: 800px) {
  h1, h2, h3, h4, h5 {
    margin-top: .5em;
    margin-bottom: .5em;
  }
  p, li, a, label, button {
    margin-top: .7em;
    margin-bottom: .7em;
  }
  h1 {
    font-size: 4em;
  }
  h2 {
    font-size: 3em;
  }
  h3 {
    font-size: 2.25em;
  }
  h4 {
    font-size: 1.75em;
  }
  h5 {
    font-size: 1.5em;
  }
  p, li, a {
    font-size: 1em;
  }
  #routes_top_container #header_container {
    width: 800px;
  }
  #routes_top_container #header_container header {
    width: 800px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  #routes_top_container #header_container header #site-tag {
    width: 300px;
    flex-direction: column;
  }
  #routes_top_container #header_container header #site-tag h3 {
    margin-top: 0;
  }
  #routes_top_container main {
    width: 800px;
  }
  #routes_top_container main #guest_landing {
    width: 100%;
  }
  #routes_top_container main #guest_landing .guest_info_section {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  #routes_top_container main #register_update_user {
    width: 800px;
  }
  #routes_top_container main #browse {
    width: 800px;
  }
  #routes_top_container main .offers_container {
    width: 800px;
  }
  #routes_top_container main .offers_container .offers_detail_section {
    width: 800px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  #routes_top_container main .offers_container .offers_detail_section span {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  #routes_top_container main .offers_container .offers_detail_section .offers_detail {
    width: 375px;
  }
  #routes_top_container main #inventory_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #routes_top_container main #inventory_container .inventory_item {
    width: 600px;
  }
  #routes_top_container main .update_inventory_submit_section {
    width: 400px;
    display: flex;
    flex-direction: column;
  }
  #routes_top_container main .update_inventory_submit_section .update_inv_btns {
    display: flex;
    justify-content: space-around;
  }
  #routes_top_container main #products_container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  #routes_top_container main #products_container .product_summary {
    width: 45%;
  }
  #site_footer {
    min-height: 20vh;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  #site_footer > div {
    width: 500px;
  }
  #site_footer > ul {
    width: 150px;
    flex-direction: column-reverse;
  }
  #site_footer > ul li {
    width: 100%;
  }
}
/*# sourceMappingURL=main.css.map */
#referrer {
    width: 100%;
    margin: 0;
    font-size: 16px;
    line-height: 25px;
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, rgba(255,255,200,1)), color-stop(75%, rgba(184,185,180,1)));
    background: linear-gradient(90deg, rgba(255,255,200,1) 50%, rgba(184,185,180,1) 75%);
}

#referrer p, #referrer a, #referrer a:visited {
    margin: 0;
    color: #000;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
}

#referrer a {
    text-decoration: none;
}

@media only screen and (min-width: 750px) {
    #referrer {
        background: -webkit-gradient(linear, left top, right top, color-stop(25%, rgba(255,255,200,1)), color-stop(50%, rgba(184,185,180,1)));
        background: linear-gradient(90deg, rgba(255,255,200,1) 25%, rgba(184,185,180,1) 50%);
    }
}

* {
   box-sizing: border-box;
}
