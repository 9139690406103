#referrer {
    width: 100%;
    margin: 0;
    font-size: 16px;
    line-height: 25px;
    background: linear-gradient(90deg, rgba(255,255,200,1) 50%, rgba(184,185,180,1) 75%);
}

#referrer p, #referrer a, #referrer a:visited {
    margin: 0;
    color: #000;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
}

#referrer a {
    text-decoration: none;
}

@media only screen and (min-width: 750px) {
    #referrer {
        background: linear-gradient(90deg, rgba(255,255,200,1) 25%, rgba(184,185,180,1) 50%);
    }
}